import React, { useState } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import Typewriter from "typewriter-effect"

const styles = createStyles({
  typewriterTitle: {
    display: "flex",
    "@media (max-width: 599.95px)": {
      flexDirection: "column",
    },
  },
  title: {
    whiteSpace: "break-spaces",
  },
  coloredCursor: {
    color: "#FF7900",
  },
})

export interface Props {
  title: string
  coloredTitle: string | string[]
  color?: string
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const TypewriterTitle = ({
  classes,
  title,
  coloredTitle,
  color,
}: PropsAndStyles) => {
  const pauseTime = 2000
  const [titleTyped, setTitleTyped] = useState(false)

  const coloredTitleArray = Array.isArray(coloredTitle)
    ? coloredTitle
    : [...coloredTitle]

  return (
    <div className={classes.typewriterTitle}>
      <Typewriter
        onInit={typewriter => {
          typewriter
            .typeString(`<span class=${classes.title}>${title} </span>`)
            .callFunction(state => {
              state.elements.cursor.style.color = "#FF7900"
            })
            .typeString(
              `<span style="color: ${color || "inherit"}" class=${
                classes.title
              }>${coloredTitleArray[0]}</span>`
            )
            .pauseFor(pauseTime)
            .deleteChars(coloredTitleArray[0]?.length)
            .callFunction(state => {
              state.elements.cursor.style.display = "none"
              setTitleTyped(true)
            })
            .start()
        }}
      />

      {titleTyped && (
        <Typewriter
          onInit={typewriter => {
            const titleArray = [...coloredTitleArray]
            titleArray.push(titleArray.shift())
            titleArray.forEach(coloredTitle => {
              typewriter
                .typeString(
                  `<span style="color: ${color || "inherit"}" class=${
                    classes.title
                  }>${coloredTitle}</span>`
                )
                .pauseFor(pauseTime)
                .deleteAll()
            })

            typewriter.start()
          }}
          options={{ loop: true, cursorClassName: classes.coloredCursor }}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(TypewriterTitle)
