import React, { ReactNode, useRef, useEffect } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { Hidden } from "@material-ui/core"

const styles = createStyles({
  featured: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    position: "relative",
    overflow: "hidden",
    "@media (max-width:959.95px)": {
      height: "auto !important",
      gridTemplateRows: "minmax(min-content, max-content) 300px",
      gridTemplateColumns: "100%",
    },
  },
  backgroundImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 20,
    "@media (max-width:959.95px)": {
      order: 1,
      justifyContent: "center",
    },
  },
  logoImage: {
    marginRight: 254,
    "@media (max-width:959.95px)": {
      marginRight: 0,
    },
  },
  content: {
    padding: 50,
    position: "relative",
    "@media (max-width:959.95px)": {
      padding: "50px 20px",
    },
  },
  canvas: {
    position: "absolute",
    transform: "rotateY(180deg)",
    width: "calc(100% + 254px)",
    height: "100%",
    top: 0,
    right: 0,
  },
  contentMain: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  thunder: {
    position: "absolute !important" as "absolute",
    right: "50%",
    zIndex: 1,
  },
})

export interface Props {
  imagePath: string
  logoPath?: string
  large?: boolean
  children?: ReactNode
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Featured = ({
  classes,
  imagePath,
  logoPath,
  large,
  children,
}: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 3840, quality: 100) {
                  aspectRatio
                  ...GatsbyImageSharpFluid
                }
                fixed(height: 483, quality: 100) {
                  width
                  height
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const compHeight = large ? 483 : 306

      const backgroundImage = data.images.edges.find(
        image => image.node.relativePath === imagePath
      )

      const thunderImage = data.images.edges.find(
        image => image.node.relativePath === "featured/thunder.png"
      )

      const logoImage = data.images.edges.find(
        image => image.node.relativePath === logoPath
      )

      const canvasRef = useRef(null)

      useEffect(() => {
        drawCanvas()
        window.addEventListener("resize", drawCanvas)

        return () => {
          window.removeEventListener("resize", drawCanvas)
        }
      }, [])

      const drawCanvas = () => {
        const canvas = canvasRef.current as HTMLCanvasElement
        const context = canvas.getContext("2d")

        context.clearRect(0, 0, canvas.width, canvas.height)

        var grd = context.createLinearGradient(0, 0, 200, 0)
        grd.addColorStop(0.0033, "#560BBB")
        grd.addColorStop(0.0295, "#560DBD")
        grd.addColorStop(0.6835, "#5945EA")
        grd.addColorStop(1, "#5A5AFC")
        context.fillStyle = grd

        const canvasSize = canvas.getBoundingClientRect()
        const topX = canvas.width - (canvas.width / canvasSize.width) * 200
        const bottomX =
          canvas.width - (canvas.width / canvasSize.width) * (large ? 5 : 100)

        context.beginPath()
        context.moveTo(0, 0)
        context.lineTo(topX, 0)
        context.lineTo(bottomX, canvas.height)
        context.lineTo(0, canvas.height)
        context.fill()
      }

      return (
        <>
          <div className={classes.featured} style={{ height: compHeight }}>
            <BackgroundImage
              className={classes.backgroundImage}
              fluid={backgroundImage?.node.childImageSharp.fluid}
            >
              {logoImage && (
                <Img
                  className={classNames(
                    logoImage?.node.childImageSharp.fixed,
                    classes.logoImage
                  )}
                  fixed={logoImage?.node.childImageSharp.fixed}
                  imgStyle={{ objectFit: "contain" }}
                />
              )}
            </BackgroundImage>

            <div className={classes.content}>
              <canvas ref={canvasRef} className={classes.canvas}></canvas>
              <div className={classes.contentMain}>{children}</div>
            </div>

            <Hidden smDown>
              <Img
                className={classNames(
                  thunderImage?.node.childImageSharp.fixed,
                  classes.thunder
                )}
                fixed={thunderImage?.node.childImageSharp.fixed}
              />
            </Hidden>
          </div>
        </>
      )
    }}
  />
)

export default withStyles(styles)(Featured)
