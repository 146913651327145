import React, { ReactNode } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const styles = createStyles({
  featuredDetails: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: " #FFFFFF",
    fontSize: "1.45rem",
    lineHeight: "2rem",
    maxWidth: 480,
    textAlign: "center",
  },
  image: {
    width: "100%",
    maxWidth: "267px !important",
    marginBottom: 25,
  },
  title: {
    fontWeight: 600,
    lineHeight: "2.67rem",
    marginBottom: 15,
  },
  description: {
    marginBottom: 20,
  },
})

export interface Props {
  title: string
  description: string
  callToAction: ReactNode
  imagePath?: string
  large?: boolean
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const FeaturedDetails = ({
  classes,
  title,
  description,
  callToAction,
  imagePath,
  large,
}: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(
        image => image.node.relativePath === imagePath
      )

      return (
        <div className={classes.featuredDetails}>
          {image && image?.node.childImageSharp.fluid && (
            <Img
              className={classNames(
                image?.node.childImageSharp.fluid,
                classes.image
              )}
              style={{
                maxWidth: image?.node.childImageSharp.fluid.presentationWidth,
              }}
              imgStyle={{ objectFit: "contain" }}
              fluid={image?.node.childImageSharp.fluid}
            />
          )}

          <div
            className={classes.title}
            style={{ fontSize: large ? "2.2rem" : "1.95rem" }}
          >
            {title}
          </div>
          <div className={classes.description}>{description}</div>

          {callToAction}
        </div>
      )
    }}
  />
)

export default withStyles(styles)(FeaturedDetails)
