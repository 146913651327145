import React, { useState } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import TypewriterTitle from "./typewriter-title"

const styles = createStyles({
  hero: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 456,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(33, 33, 33, 0.75)",
    zIndex: -1,
    mixBlendMode: "multiply",
  },
  heroContent: {
    maxWidth: 800,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "0 20px",
  },
  title: {
    fontWeight: 800,
    fontSize: "3.1rem",
    lineHeight: "4.44rem",
    paddingBottom: 30,
  },
  coloredTitle: {
    color: "#FF7900",
  },
  description: {
    fontSize: "1.45rem",
    lineHeight: "2rem",
    paddingBottom: 30,
  },
  icon: {
    width: 60,
    height: 60,
  },
})

export interface Props {
  imagePath: string
  title: string
  coloredTitle: string | string[]
  description?: string
  icon?: JSX.Element
  large?: boolean
  typewriterAnimation?: boolean
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Hero = ({
  classes,
  imagePath,
  title,
  coloredTitle,
  description,
  icon,
  large,
  typewriterAnimation,
}: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 3840, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(
        image => image.node.relativePath === imagePath
      )

      return (
        <BackgroundImage
          className={classes.hero}
          style={{ height: large ? 666 : 456 }}
          fluid={image?.node.childImageSharp.fluid}
        >
          <div className={classes.heroContent}>
            <div className={classes.title}>
              {!typewriterAnimation ? (
                <>
                  <span>{title}</span>
                  <span className={classes.coloredTitle}> {coloredTitle}</span>
                </>
              ) : (
                <TypewriterTitle
                  title={title}
                  coloredTitle={coloredTitle}
                  color="#FF7900"
                />
              )}
            </div>
            {description && (
              <div className={classes.description}>{description}</div>
            )}
            {icon && <div className={classes.icon}>{icon}</div>}
          </div>
          <div className={classes.overlay}></div>
        </BackgroundImage>
      )
    }}
  />
)

export default withStyles(styles)(Hero)
